// seo.service.ts
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private titleService: Title, private metaService: Meta) { }

    updateMetaTags(productName: string, CatalogueNumber: string) {
        this.titleService.setTitle(`${productName} - Azolechem`);
        this.metaService.updateTag({ name: 'description', content: 'info' });
    }
}
